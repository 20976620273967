var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"payment-detail-container pb-3"},[_c('v-layout',{attrs:{"d-flex":"","align-center":""}},[_c('h4',{staticClass:"pl-3 my-3"},[_vm._v(_vm._s(_vm.$t("text.details")))]),_c('v-spacer'),(_vm.isDraft || _vm.isProcessing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"icon":""},on:{"click":_vm.insert}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,4205414286)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.add-order')))])]):_vm._e()],1),(_vm.properties.details.length)?_c('v-card',{staticClass:"mx-3 py-3",attrs:{"outlined":""}},_vm._l((_vm.properties.details),function(detail,i){return _c('v-container',{key:detail.id,staticClass:"py-0",attrs:{"fluid":""}},[(i !== 0)?_c('v-row',[_c('v-divider',{staticClass:"blue lighten-3 mt-3"})],1):_vm._e(),_c('v-row',{staticClass:"pl-2"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"subtitle-2"},on),[_vm._v(" "+_vm._s(detail.customerOrder.uniqueNo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-no')))])])],1),_c('v-col',{attrs:{"cols":"5","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatDate(detail.customerOrder.orderDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.order-date')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"red--text darken-1"},on),[_vm._v(" "+_vm._s(_vm.formatAmount(detail.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"auto"}},[(_vm.isDraft || _vm.isProcessing)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.removeDialog(detail)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.expand(detail)}}},on),[_c('v-icon',[_vm._v(_vm._s(detail.customerOrder.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.expand')))])])],1)],1),(detail.customerOrder.expanded)?_c('v-row',[_c('v-divider')],1):_vm._e(),(detail.customerOrder.expanded)?_c('v-row',{staticClass:"px-0"},[_c('v-col',{staticClass:"py-0 px-0"},[_c('CustomerPaymentProductView',{attrs:{"properties":_vm.properties,"alertDialog":_vm.alertDialog,"detail":detail}})],1)],1):_vm._e()],1)}),1):_vm._e(),(!_vm.properties.details.length)?_c('v-card',{staticClass:"mx-3 py-3 text-center",attrs:{"outlined":""}},[_vm._v(_vm._s(_vm.$t("$vuetify.noDataText")))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }