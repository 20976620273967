































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerPaymentInfoProperties } from "./CustomerPaymentInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerPaymentDetailService from "@/services/CustomerPaymentDetailService";
import DateUtil from '@/utilities/DateUtil';
import { CustomerPaymentCustomerOrderProperties } from "./CustomerPaymentCustomerOrderView.vue";

const CustomerPaymentProductView = () => import("./CustomerPaymentProductView.vue");

@Component({
    components: { CustomerPaymentProductView }
})
export default class CustomerPaymentDetailView extends Vue {
    @Prop() private properties: CustomerPaymentInfoProperties;
    @Prop() private customerPaymentCustomerOrder: CustomerPaymentCustomerOrderProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerPaymentDetailService = new CustomerPaymentDetailService();

    public get payment() {
        return this.properties.payment;
    }

    public get currency() {
        return this.properties.currency;
    }

    public get isDraft() {
        return this.payment.status === "Draft";
    }

    public get isProcessing() {
        return this.payment.status === "Processing";
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public formatAmount(v: any) {
        const symbol = this.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

    public created() {        
        this.properties.events.subscribe("load-details", this.load);
        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
        this.load();
    }

    public destroyed() {
        this.properties.events.remove("load-details", this.load);
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public async load() {
        try {
            const payment = this.properties.payment;
            if (payment.id) {
                const r = await this.customerPaymentDetailService.get({
                    customerPaymentId: payment.id,
                    loadCustomerOrder: true,
                });

                this.properties.details = r.data.customerPaymentDetails;
            } else {
                this.properties.details = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async remove(detail: any) {
        if ((!this.isDraft && !this.isProcessing) || this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            await this.customerPaymentDetailService.delete(detail);
            await this.properties.events.fire('load');
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public expand(detail: any) {
        const expanded = detail.customerOrder.expanded;
        Vue.set(detail.customerOrder, "expanded", !(expanded ?? false));
    }

    public insert() {
        if (this.isDraft || this.isProcessing) {
            this.customerPaymentCustomerOrder.visible = true;
        }
    }

    public removeDialog(detail: any) {        
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove-detail", detail };
        this.alertDialog.visible = true;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "remove-detail" && button === this.$t("text.yes")) {
                await this.remove(tag.detail);
            }
        }
    }
}
